<template>
    <Row>
        <Col span="4" >
            <span class="text-link">{{ value.id }} : </span>
        </Col>
        <Col span="20">
            <AutoComplete
                v-model="search_key"
                placeholder="搜索"
                @on-change = "doSearch"
                @on-blur  = 'blur'
                @on-focus = 'focus'
            >
                <Option v-for="option in datas" :value="option.name" :tag="option.id" :key="option.id" @click.native="onClicked(option)">
                    <span class="text-link">{{ option.id }}:</span> 
                    <span class="text-main">{{ option.name }}</span>
                    <span class="text-assist">({{ option.sport_name }})</span>
                </Option>
            </AutoComplete>
        </Col>
    </Row>
</template>

<script>

import { searchSubSport } from '@/api/olympics/subsport';

export default {
    name: "subsport-search",
    props: {
        value     : Object,    // {id, name, logo}
        sport_id  : Number,     // 大运动项目id
        season_id : Number,     // 奥运会id
    },
    components: {
    },
    data () {
        let self = (window.x = this);
        return {
            search_key: '',
            data: {
                'id': 0,
                'name': '',
                'logo': '',
            },
            datas: {},
            loading: false,
        }
    },
    methods: {
        focus(){
            let self = this;
            // console.log("on focus")
            self.loading = true;
            let params = {
                search_key: '',
                sport_id  : self.sport_id,
                season_id : self.season_id,
            }
            searchSubSport(params).then(response => {
                if (response.data.code == 0) {
                    self.datas = response.data.data.list;
                } else {
                    this.$Message.error(response.data.msg);
                }
            })
            self.loading = false;
        },
        // end 
        doSearch (value) {
            let self = this;
            // console.log("subsport SEARCH:", value,  self.value);
            if (value && (value !== self.data.name)){
                self.loading = true;
                let params = {
                    search_key: value,
                    sport_id  : self.sport_id,
                    season_id : self.season_id,
                }
                searchSubSport(params).then(response => {
                    if (response.data.code == 0) {
                        self.datas = response.data.data.list;
                    } else {
                        this.$Message.error(response.data.msg);
                    }
                })
                self.loading = false;
            }else if (value && ( value === self.data.name)){
                self.datas = {}
                self.datas [self.data.id]= self.data
            }
        },
        // end
        onClicked(data){
            // console.log("onClicked(option)", option)
            let self = this;
            self.data = data;
            self._emit();
            self.search_key = self.data.name;
        },
        // end
        _emit(){
            this.$emit('input', this.data);
        },
        // end
        blur(){
            // 失去焦点时触发
            let self = this;
            if (self.search_key){
                self.search_key = self.data.name;
            }else{
                self.data = {
                    id:0,
                    name: '',
                    logo: ''
                };
                self.datas = [];
            }
            self._emit();
        },
        // end
    },
    mounted () {
    },
    watch: {
        'value': {
            handler: function(value){
                this.data = value;
                this.search_key = value.name;
            },
            deep: true,
        },
        // end 
    },
    computed: {
    }
}
</script>
<style scoped>
    /* @import "search.css" */
</style>