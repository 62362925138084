import http from '@/utils/http';

// 获取国家/地区
export const getPlayerList = (params) => {
    return http.request({
        url: "/v1/olympics/player/list",
        method: 'get',
        params: params,
    })
}

// 更新国家/地区
export const updatePlayer = (params) => {
    return http.request({
        url: "/v1/olympics/player/update",
        method: 'post',
        data: params,
    })
}

// 搜索
export const searchPlayer = (params) => {
    return http.request({
        url: "/v1/olympics/player/search",
        method: 'post',
        data: params,
    })
}
