import http from '@/utils/http';

// 获取
export const getSubSportList = (params) => {
    return http.request({
        url: "/v1/olympics/subsport/list",
        method: 'get',
        params: params,
    })
}

// 更新
export const updateSubSport = (params) => {
    return http.request({
        url: "/v1/olympics/subsport/update",
        method: 'post',
        data: params,
    })
}

// 搜索
export const searchSubSport = (params) => {
    return http.request({
        url: "/v1/olympics/subsport/search",
        method: 'post',
        data: params,
    })
}
